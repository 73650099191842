import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useCode } from '../../hooks/codes';
import resourcesRepository from '../../lib/resource';
import interactionRepository from '../../lib/interaction';

import Loading from '../../components/Loading';
import Details from '../../components/Details';
import { Container, Header, Logo, HeaderSearch } from './styles';
import ProductByCode from '../../components/ProductByCode';
import ResourceDTO from '../../dtos/Resource';

import { toast } from 'react-toastify';

interface Params {
  value?: string;
}

const CodeInfo: React.FC = () => {
  const [search, setSearch] = useState('');
  const { code, loading, find } = useCode();

  const { value } = useParams<Params>();
  const history = useHistory();

  const [latLng] = useState<{ lat: number; lng: number }>();
  const [resource, setResource] = useState<ResourceDTO>();
  const [loadingResources, setLoadingResources] = useState(false);

  const [searchAttempted, setSearchAttempted] = useState(!!value);

  const [searchFinished, setSearchFinished] = useState(false);

  const lastToastCodeRef = useRef<string | null>(null);

  const logAndToastError = (error: unknown) => {
    const errorMessage = error instanceof Error ? error.message : String(error);
    console.error('Erro ao buscar recursos:', errorMessage);
    toast.error(errorMessage);
  };

  const findResources = useCallback(
    async (id: string) => {
      setLoadingResources(true);
      try {
        const data = await resourcesRepository.find(id, value || '');
        if (!data) {
          throw new Error(`Não foram encontrados recursos para o ID: ${id}`);
        }
        setResource(data);
      } catch (error) {
        logAndToastError(error);
      } finally {
        setLoadingResources(false);
      }
    },
    [value]
  );

  useEffect(() => {
    if (code?.resourceId) {
      findResources(code.resourceId);
    }
  }, [code, findResources]);

  useEffect(() => {
    if (value) {
      setSearchAttempted(true);
      lastToastCodeRef.current = null;
      setSearchFinished(false);

      find(value)
        .then(() => {
          setSearchFinished(true);
        })
        .catch((err) => {
          console.error('Erro ao buscar o código:', err);
          setSearchFinished(true);
        });
    } else {
      setSearchFinished(true);
    }
  }, [value, find]);


  useEffect(() => {
    console.log('useEffect do toast => ', {
      searchAttempted,
      loading,
      searchFinished,
      code,
    });
  
    if (!searchAttempted || loading || !searchFinished) {
      return;
    }
  
    if (!code) {
      return;
    }
  
    const isCodeEmpty = Object.keys(code).length === 0;
    if (isCodeEmpty) {
      return;
    }
  
    if (!code.value) {
      return;
    }
  
    const codeValueOrEmpty = code.value ?? 'SEM_CODE';
    const displayStatusIsNull = code.resource?.displayStatus === null;
  
    if (!code.resourceId || displayStatusIsNull) {
      if (lastToastCodeRef.current !== codeValueOrEmpty) {
        toast.warn('Garantia de origem controlada', {
          style: {
            backgroundColor: '#f0ad4e',
            color: '#1e1e1e',
            fontWeight: 'bold',
          },
          toastId: 'garantia-origem-controlada',
        });
        lastToastCodeRef.current = codeValueOrEmpty;
      }
    } else {
      lastToastCodeRef.current = null;
    }
  }, [searchAttempted, loading, searchFinished, code]);  

  useEffect(() => {
    if (resource?.id && latLng) {
      const saveInteractionView = async () => {
        try {
          await interactionRepository.saveInteraction({
            resourceId: resource.id,
            code: code.value,
            latitude: latLng.lat,
            longitude: latLng.lng,
            status: 'Envasado',
          });
        } catch (error) {
          console.error('Erro ao salvar interação:', error);
        }
      };
      saveInteractionView();
    }
  }, [resource, latLng, code]);

  const handleClearSearch = () => {
    setSearch('');
  };

  const handleSearch = (event: React.FormEvent<Element>) => {
    event.preventDefault();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          if (!search) {
            toast.error('Digite um código para buscar.');
            return;
          }

          setSearchAttempted(true);
          lastToastCodeRef.current = null; 
          history.push(`/${search}`);
          setSearchFinished(false);
          find(search)
            .then(() => {
              setSearchFinished(true);
            })
            .catch((err) => {
              console.error('Erro ao buscar o código:', err);
              setSearchFinished(true);
            });
        },
        (error) => {
          if (error.code === 1) {
            toast.error('Localização desativada. Por favor, ative para continuar.');
          } else {
            toast.error('Erro ao tentar acessar a localização.');
          }
        }
      );
    } else {
      toast.error('Geolocalização não é suportada neste navegador.');
    }
  };

  return (
    <Container
      imageBuffer={resource?.image ? Buffer.from(resource.image.data) : undefined}
    >
      <Header>
        <Logo />
        <HeaderSearch
          placeholder="Pesquisar código"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSubmit={handleSearch}
          clearSearch={handleClearSearch}
          disabled={loading}
        />
      </Header>

      {loading && <Loading />}

      {!loading && code?.resourceId ? (
        <ProductByCode
          qrCode={code}
          resource={resource}
          loadingResources={loadingResources}
        />
      ) : (
        <Details code={code} />
      )}
    </Container>
  );
};

export default CodeInfo;
