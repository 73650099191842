import React from 'react';

import { IDetailsProps } from './props';
import Code from '../../dtos/Code';

import Imbel from './Imbel';
import Saas, { SaasProps } from './Saas';

function isSaasProps(object: any): object is SaasProps {
  return 'fields' in object && 'value' in object && 'resourceId' in object;
}

interface DetailsProps<T = Code | SaasProps> {
  code: T;
}

const Details: React.FC<DetailsProps> = ({ code }) => {
  console.log('Detalhes do código recebido:', code);

  let ComponentToRender: React.FC<IDetailsProps>;
  let componentProps: SaasProps | Code | {};

  if (isSaasProps(code)) {
    ComponentToRender = Saas;
    componentProps = code;
    console.log('Renderizando Saas com:', code);
  } else {
    ComponentToRender = Imbel;
    componentProps = code;
  }

  return <ComponentToRender data={componentProps as any} />;
}

export default Details;
